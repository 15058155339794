import React, { useEffect } from 'react';

import { useNavigate } from '@hooks/useNavigate';

const ReceiptPage = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  /**
   * Just for backward compability with old site
   *
   * TODO: make normal redirect using other ways
   */

  useEffect(() => {
    window.location.href = `${window.location.origin}/uk/receipts/${id}`;
  }, []);

  return null;
};

export default ReceiptPage;
